/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  roles: [],
}

export default function user(state = INITIAL_STATE, action: {
  type: any;
  payload: {
    user: null;
    roles: never[]
  }
}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user
        draft.roles = action.payload.roles
        break
      }
      case '@auth/SET_USER_UPDATE': {
        draft.profile = action.payload.user
        break
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null
        break
      }
      default:
        return state
    }
  })
}
