export function signInRequest(email: string, password: string) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  }
}

export function signInRequestUser({ id }: { id: string }) {
  return {
    type: '@auth/SIGN_IN_REQUEST_USER',
    payload: { id },
  }
}

export function signInSuccess(user: any, roles: any, accessToken: string, refreshToken: string, expiresIn: string) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { user, roles, accessToken, refreshToken, expiresIn },
  }
}

export function signUpRequest(email: string, password: string, confirmPassword: string) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { email, password, confirmPassword },
  }
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  }
}
export function RefleshToken() {
  return {
    type: '@auth/REFLESH_TOKEN_REQUEST',
  }
}

export function RefleshTokenSucess(accessToken: string, refreshToken: string, expiresIn: string) {
  return {
    type: '@auth/REFLESH_TOKEN_SUCCESS',
    payload: { accessToken, refreshToken, expiresIn },
  }
}

export function RefleshTokenFailure() {
  return {
    type: '@auth/REFLESH_TOKEN_FAILURE',
  }
}