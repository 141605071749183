import axios, { AxiosRequestHeaders } from 'axios'
import { store } from 'src/store'
import { signOut } from 'src/store/modules/auth/actions'
import { endpoints } from '../.env'

interface FailedRequest {
  resolve: (token: string) => void
  reject: (error: any) => void
}

const api = axios.create({
  baseURL: endpoints.api,
})

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('accessToken');
  const newConfig = { ...config };
  newConfig.headers = { ...config.headers } as AxiosRequestHeaders;
  newConfig.headers.authorization = `Bearer ${token}`;
  return newConfig;
});

let isRefreshing = false
let failedRequestsQueue: FailedRequest[] = []

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!isRefreshing) {
        isRefreshing = true

        try {
          const refreshResponse = await axios.post(`${endpoints.api}/auth-keyklock/refresh-token`, {
            refreshToken: localStorage.getItem('refreshToken'),
          })

          const newToken: string = refreshResponse.data.accessToken
          const newRefreshToken: string = refreshResponse.data.refreshToken
          
          localStorage.setItem('accessToken', newToken)
          localStorage.setItem('refreshToken', newRefreshToken)

          failedRequestsQueue.forEach((request) => request.resolve(newToken))
          failedRequestsQueue = []

          return api(originalRequest)
        } catch (refreshError) {
          failedRequestsQueue.forEach((request) => request.reject(refreshError))
          failedRequestsQueue = []

          if (window.location.pathname !== '/login' && window.location.pathname !== "/") {
            window.location.href = '/login'
          }
     

          store.dispatch(signOut())

          return Promise.reject(refreshError)
        } finally {
          isRefreshing = false
        }
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          resolve: () => {
            resolve(api(originalRequest))
          },
          reject: (err) => reject(err),
        })
      })
    }

    return Promise.reject(error)
  }
)

export default api
