import { Button, Col, Form, Input, message, Row } from 'antd';
import React, { useState } from 'react';
//@ts-ignore
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
//@ts-ignore
import Logo from '../../../assets/logo.png';

import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { getOnlyNumbers } from 'src/helpers/utils';
import api from 'src/services/api';
import { Container, Title, Wrapper } from './styles';

const ForgotPassword: React.FC = () => {
    //const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm();
    const [step, setStep] = useState<number>(1)
    const [numberZap, setNumberZap] = useState<number | null>(null)
    const [token, setToken] = useState<string>("")
    const navigate = useNavigate()

    const onSubmit = async () => {
        const value = form.getFieldsValue(true);
        try {
            setLoading(true)
            if (step === 1) {
                const { data } = await api.post("/code/forgot-password-admin", value);
                if (!data?.Number) {
                   return message.error("Erro interno. Tente novamente em instantes.")
                }
                setNumberZap(data?.Number || null)
                message.success('Código enviado com sucesso!');
            }
            if (step === 2) {
                const { data } = await api.post("/code/confirm-code-admin", value);
                if (!data?.token) {
                    return message.error("Erro interno. Tente novamente em instantes.")
                }
                setToken(data?.token)
                message.success('Código confirmado com sucesso!');
            }
            if (step === 3) {
                await api.post("/code/changePassowrd-admin", { ...value, token });
                message.success('Senha alterada com sucesso!');
                navigate('/', { replace: true })
            }
            setStep(step + 1)
        } catch (error: any) {
            message.error(error?.response?.data?.message ||
                'Um erro inesperado aconteceu, tente novamente mais tarde',
            );
        } finally {
            setLoading(false)
        }
    };

    const getValidationPassword = (value: any) => {
        const values = form.getFieldsValue(true)
        if (values.password !== value) {
            return true
        }
        return false
    }

    return (
        <Container>
            <Wrapper>
                <Form onFinish={onSubmit} form={form} layout="vertical">
                    <img src={Logo} alt="ShoppBud" style={{ width: "27%", marginBottom: 10 }} />
                    <Title>
                        Recupere sua senha
                    </Title>
                    <Row style={{ flexGrow: 1 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {step === 1 && (
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            transform: (value: string) => value.trim(),
                                            max: 255,
                                            message: 'Email inválido',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                            {step === 2 && (
                                <>
                                    <div style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex"
                                    }}>
                                        <p style={{ color: "red", textAlign: "center" }}>
                                            Informe o código enviado para o <br /> WhatsApp com o número final {numberZap}.</p>
                                    </div>
                                    <Form.Item
                                        label="Informe o Código"
                                        name="code"
                                        rules={[
                                            {
                                                required: true,
                                                transform: getOnlyNumbers,
                                                min: 6,
                                                max: 6,
                                                message: 'Por favor, informe um código válido',
                                            },
                                        ]}
                                    >
                                        <InputMask mask="999999">
                                            <Input />
                                        </InputMask>
                                    </Form.Item>
                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <Form.Item
                                        label="Senha (mínimo de 8 caracteres)"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor, insira a senha.',
                                                min: 8,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            iconRender={(visible) =>
                                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirme a senha"
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    const inputLength = getValidationPassword(value)

                                                    if (inputLength)
                                                        return Promise.reject(new Error('Senhas não confere.'))
                                                    return Promise.resolve()
                                                },
                                                required: true,
                                                min: 8,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            iconRender={(visible) =>
                                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                            }
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </Col>
                    </Row>

                    <Row style={{ flexGrow: 1 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Button
                                className="default-btn"
                                htmlType="submit"
                                style={{ background: '#0593d2', color: 'white', width: '100%' }}
                                loading={loading}
                            >
                                {step === 1 ? "Enviar" : "Confirmar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Wrapper>
        </Container>
    );
};

export default ForgotPassword;
