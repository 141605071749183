import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './routes/PrivateRoute'
import './scss/style.scss'
import ForgotPassword from './views/pages/ForgotPassword'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const PagamentoLink = React.lazy(() => import('./views/pages/LinkPayments'))

export function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login" element={<Login />} />
          <Route exact path="/forgotpassword" name="Forgot Password" element={<ForgotPassword />} />
          <Route
            exact
            path="/linkpayments/:codAcesso"
            name="Pagamento"
            element={<PagamentoLink />}
          />
          <Route exact path="linkpayments" name="Page 404" element={<Page404 />} />

          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={
              <PrivateRoute>
                <DefaultLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
