/* eslint-disable import/no-duplicates */
import { format, isBefore, differenceInDays } from 'date-fns'
import { DateTime } from 'luxon'
import ptBr from 'date-fns/locale/pt-BR'
import api from 'src/services/api'
import moment, { Moment } from 'moment';

import { CancelToken } from 'axios'
import { FormInstance } from 'antd'
import { RcFile } from 'antd/lib/upload'

export interface ISelect {
  value: string;
  label: string;
}

export enum MethodList {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export function listHTTPMethods(): MethodList[] {
  return Object.values(MethodList);
}


const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

export const getOnlyNumbers = (value: string) => String(value)?.replace(/[^\d]/g, '')


export const hasOnlyLetters = (value: string): string | boolean => {
  if (!value) return '';
  return value.match(/[^\w]/g) === null;
};
export const getMaxAge = () => moment().subtract(120, 'years')
export const getMinAge = () => moment().subtract(1, 'years')

export const validateBirthDate = (date: Moment | null): boolean => {
  if (
      (date && date.year() > getMinAge().year()) ||
      (date && date.year() < getMaxAge().year())
  ) {
      return true;
  }
  return false;
};

export const convertBirthDateToenUS = (date: string): string => {
  const convertedBirthDate = moment(date, 'DD/MM/YYYY').format(
      'YYYY-MM-DDTHH:mm:ss',
  );
  return convertedBirthDate;
};

export const numberFormatted = (value: string): number => {
  if (!value) return 0;
  const stringValue = String(value);
  const isMalFormatedNumber = stringValue.match(/[,|.]{2,}/g);
  if (isMalFormatedNumber) return 0;
  const removedNonNumeric = stringValue.replace(/[^\d|.|,]/g, '');
  //@ts-ignore
  return +removedNonNumeric?.replaceAll(',', '.');
};


export const formatToNumberInteger = (value: number): number | null => {
  if (!value) return null;

  const price: any = value;
  const decimalConvert = Number(Math.round(price * 1) / 100)
      .toFixed(2)
      .replace('.', ',');

  return parseFloat(decimalConvert);
};

export const formatToNumberFloat = (value: number): number | null => {
  if (!value) return null;
  const price: any = value;
  const decimalConvert = Number(price / 100);
  return decimalConvert;
};

export const isOnlyNum = (value: string): boolean =>
  String(value).match(/[^\d]/g) === null;

export const isValidNumber = (value: string): number | null => {
  if (!value) return null;
  const newNumber = numberFormatted(value);
  return (newNumber || 1) * 1;
};

export const normalize = (value: string): string =>
  String(value)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

export const hasEqualItem = (
  list: Array<any>,
  key: string,
  value: string,
): boolean => {
  if (list.length < 1) return false;
  return list.some(
      (item) =>
          normalize(String(item[key]).toLocaleLowerCase()) ===
          normalize(String(value).toLocaleLowerCase()),
  );
};

export const cnpjFormatted = (cnpj: string): string =>
  cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

export const cpfFormatted = (cpf: string): string => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const cepFormatted = (cep: string): string => {
  return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const phoneFormatted = (phone: string): string =>
  phone.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

export const formatToPrice = (value: number | undefined): string => {
  if (!value && value !== 0) return '';
  return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      currencySign: 'accounting',
  }).format(value);
};

export const toBase64 = (file: File | RcFile | any): any => {
  return new Promise((resolve, reject) => {
      if (file.base64) return resolve(file);

      const reader = new FileReader();

      reader.readAsDataURL(file.originFileObj || file);
      reader.onload = () =>
          resolve({ name: file.name, base64: reader.result });
      reader.onerror = (error) => reject(error);
  });
};

export const formatWithPtBrLocale = (
  date: Date,
  formatDate: string,
): string => {
  return format(date, formatDate, { locale: ptBr });
};

export const formatDateAndHour = (date: Date) => {
  const dateFormatted = format(date, 'dd/MM/yyyy - HH:mm', {
    locale: ptBr,
  })
  return dateFormatted
}

export const formatDateToSendToApi = (value: string) => {
  const dateFormatted = DateTime.fromFormat(value, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
  return dateFormatted
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fakeRequest = ({ onSuccess }: any): void => {
  setTimeout(() => {
      if (onSuccess) onSuccess('ok', new XMLHttpRequest());
  }, 0);
};

export const createFormData = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  obj: any,
  isUpdate = false, // to be use when is a update form
  restrictions: string[] = [], // restriction is the key that its value will NOT be deleted when update itself
): FormData => {
  const form = new FormData();

  for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          // conditions
          const isNotNull = value !== null;
          const isNotUndefined = value !== undefined;
          const isRestriction = restrictions.includes(key);
          // handle nested objects
          if (isObject(value)) addObjectToForm(form, value, key);
          // handle arrays
          else if (isArray(value)) addArrayToForm(form, value, key);
          // handle create form truthy values
          else if (!isUpdate && isNotNull && isNotUndefined)
              form.append(key, value);
          // handle update form values
          else if (isUpdate) {
              if (!isRestriction) {
                  const sanitizedValue =
                      value === false || value ? value : '';
                  form.append(key, sanitizedValue);
              } else if (isNotNull && isNotUndefined) form.append(key, value);
          }
      }
  }
  return form;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isObject = (obj: any): boolean => {
  if (
      typeof obj === 'object' &&
      Array.isArray(obj) === false &&
      obj !== null &&
      !(obj instanceof File)
  )
      return true;
  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isArray = (obj: any): boolean => {
  if (Array.isArray(obj)) return true;
  return false;
};

const addArrayToForm = (form: FormData, array: any, key: string): void => {
  array.forEach((item: any, index: number) => {
      return form.append(`${key}[${index}]`, item);
  });
};

const addObjectToForm = (form: FormData, obj: any, key: string) => {
  for (const subKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, subKey)) {
          const value = obj[subKey];
          if (value !== undefined && value !== null)
              form.append(`${key}.${subKey}`, value);
      }
  }
};

export const getLabel = (
  arr: {
      name?: string | any;
      id?: string | any;
      corporate_name?: string | any;
      shopmanager_id?: number | any;
  }[],
): ISelect[] => {
  const newArr: ISelect[] = [];

  arr.forEach((value) =>
      newArr.push({
          label: value.name || value.corporate_name,
          value: value.id,
      }),
  );

  return newArr;
};

export const getSelectValue = async (
  params: {
      url: string;
      method: MethodList;
      body?: {};
      cancelToken?: CancelToken;
  },
  stateFunction: (value: React.SetStateAction<any>) => void,
  fetch: (params: any) => Promise<void>,
): Promise<void> => {
  const response: any = await fetch(params);
  if (!response || response.statusCode === 499) {
      return;
  }
  const resultRequeste = response?.data ? response.data : [];
  stateFunction(resultRequeste);
};

export const validateImage = (file: File): string[] | [] => {
  const isInvalidSize = file?.size > 20971520; // 20MB
  const isInvalidFormat = !SUPPORTED_FORMATS.includes(file?.type);
  const formatsSupported = SUPPORTED_FORMATS.map((format) =>
      format.replace('image/', '.'),
  ).join(', ');

  const invalidSizeMsg = 'Imagem excede o tamanho de 20MB';
  const invalidFormatMsg = `Formato de imagem precisa ser ${formatsSupported}`;

  if (isInvalidSize && isInvalidFormat)
      return [invalidSizeMsg, invalidFormatMsg];
  if (isInvalidSize) return [invalidSizeMsg];
  if (isInvalidFormat) return [invalidFormatMsg];
  return [];
};

export const dateIsOlderThanToday = (date: string): boolean => {
  const today = new Date();
  const dateToCompare = new Date(date);

  if (isBefore(dateToCompare, today))
      return differenceInDays(today, dateToCompare) >= 1;

  return false;
};

export const formatDateBirthday = (birthday: string): string => {
  const dateBirthday = birthday;
  const day = dateBirthday.substring(8, 10);
  const month = dateBirthday.substring(5, 7);
  const year = dateBirthday.substring(0, 4);

  return `${day}/${month}/${year}`;
};

export const getDocType = (doc: string, form?: FormInstance<any>): any => {
  if (doc) {
      const numbers = getOnlyNumbers(doc);

      if (numbers.length < 11) return 'cpf';
      if (numbers.length >= 11) return 'cnpj';
  }

  return form?.setFields([{ name: 'doc', errors: ['CPF/CNPJ inválido'] }]);
};

export const pixSanitization = (typePix: string, pix: string): any => {
  switch (typePix) {
      case 'cpf':
      case 'cnpj':
      case 'cellphone':
          return getOnlyNumbers(pix);
      default:
          return pix;
  }
};

export const getCEP = async (cep: string) => {
  return await api.get(`/boleto/cep/${cep}`)
}

export type ModalStates = 'block' | 'unlock' | 'delete' | null
