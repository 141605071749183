import produce from 'immer';

const INITIAL_STATE = {
  signed: false,
  loading: false,
  accessToken: null,
  refreshToken: null,
  expiresIn: null
}

export default function auth(state = INITIAL_STATE, action: {
  type: any;
  payload: {
    token: any;
    accessToken: any;
    refreshToken: any;
    expiresIn: any;
  }
}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true
        break
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.accessToken = action?.payload?.accessToken
        draft.refreshToken = action?.payload?.refreshToken
        draft.expiresIn = action?.payload?.expiresIn
        draft.signed = true
        draft.loading = false
        break
      }
      case '@auth/SIGN_FAILURE': {
        draft.signed = false
        draft.loading = false
        draft.accessToken = null
        draft.refreshToken = null
        draft.expiresIn = null
        break
      }
      case '@auth/SIGN_OUT': {
        draft.signed = false
        draft.loading = false
        draft.accessToken = null
        draft.refreshToken = null
        draft.expiresIn = null

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('@usernameadmin:servemei')
        localStorage.removeItem('@passwordadmin:servemei')
        localStorage.removeItem('@passwordadmin:id')
        break
      }
      case '@auth/REFLESH_TOKEN_FAILURE': {
        draft.signed = false
        draft.loading = false
        draft.accessToken = null
        draft.refreshToken = null
        draft.expiresIn = null
        break
      }
      case '@auth/REFLESH_TOKEN_SUCCESS': {
        draft.accessToken = action?.payload?.accessToken
        draft.refreshToken = action?.payload?.refreshToken
        draft.expiresIn = action?.payload?.expiresIn
        break
      }
      default:
    }
  })
}