import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
    height: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    `}
`;

export const Title = styled.p`
    ${({ theme }) => css`
        font-size: 17px;
        color: #141322;
        align-self: center;
        font-family: "Nunito Sans";
    `}
`;


export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        height: fit-content;
        flex-direction: column;
        width: 100%;
        background-color:  "white";
        min-width: 42rem;
        padding: 2.6rem;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
    `}
`;

export const SignUpText = styled.p`
    ${({ theme }) => css`
        font-size: 15;
        color: #595c97;
        margin-top: 2rem;
        text-align: center;
    `}
`;

export const Footer = styled.p``;
